import {
  cpIcon,
  dashboardIcon,
  labelServiceIcon,
  labelsIcon,
  PaymentIcon,
  referenceIcon,
  reportIcon,
  serviceReportIcon,
  userIcon,
  uploadIcon,
  scanOR,
  history, sidedashboardActive,sidereportActive,sidescanActive,sideuploadActive
} from '../assets/images/img';

let isLocal = false; 
let isStaging = false;

let backendUrl = "";
let frontendUrl = "";

if (isLocal) {
  frontendUrl = `http://localhost:3000`;
  backendUrl = `http://127.0.0.1:3004`;
}
else if (isStaging) {
  frontendUrl = `https://stg.evisaverify.world`
  backendUrl = `https://api-stg.evisaverify.world`;
}
else {
  frontendUrl = `https://evisaverify.world`;
  backendUrl = `https://api.evisaverify.world`;
}

export const APP_DOMAIN_PREFIX = backendUrl;
export const APP_FRONT_PREFIX = frontendUrl;
// export const APP_DOMAIN_PREFIX = `http://127.0.0.1:3004`;  /** For local */
// export const APP_DOMAIN_PREFIX = `api-stg.evisaverify.world`; /** For Staging */
// export const APP_DOMAIN_PREFIX = `https://api.evisaverify.world`; /** For Production */
export const API_URL = `${APP_DOMAIN_PREFIX}/api/v1`;
export const FILE_BASE_URL = 'http=//localhost=9000/';

export const G_MAPS_API_KEY = 'AIzaSyAIPAC1LtBnHkZDAnWUa914ggtqvk02X9M'; // OLD KEY - PERMISSION_DENIED: You must enable Billing on the Google Cloud Project
//export const G_MAPS_API_KEY = 'AIzaSyAkpc9tUdYPsnpBEVG1OFD7YtZFOGX1GoY'; // Also used in teleschool

export const LABELS = {
  GO_BACK: '← Back',
  LOGOUT: '↶ Logout',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  REGISTER: 'Create User',
  EMAIL: 'Email Address',
  NAME: 'Username',
  FULL_NAME: 'Full Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  INVALID_MOBILE: 'Invalid mobile number'
};
export const PASSWORD = {
  passwordLength: 6,
  passwordLengthError: 'password is to short'
}
export const REGISTER = {
  SUCCESS_HEADER: "Success",
  SUCCESS_MESSAGE: "User Created Successfully!",
  FAILURE_HEADER: "Failure",
  FAILURE_MESSAGE: "Cannot Create User! User may already have been created with the given email!"
};
export const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2;3})+$/;
export const C_OTC_STORAGE = 'c_d_storage';

// export const user = JSON.parse(localStorage.getItem('user'));
// export const isAdmin = user ? user.role.type === 1 : true;

export const sidebarTabsList = [
  // { text: 'user management', key: 'users', icon: userIcon },
  { text: 'dashboard', key: 'dashboard', icon: dashboardIcon, activeIcon: sidedashboardActive },
  // { text: 'upload new Manifest', key: 'upload-manifest', icon: uploadIcon, activeIcon: sideuploadActive },
  { text: 'Manifest', key: 'manifest', icon: uploadIcon, activeIcon: sideuploadActive },
  // { text: 'manifest history', key: 'manifest', icon: history, activeIcon: sidereportActive },
  { text: 'QR Tracking Scan', key: 'qr-scan', icon: scanOR, activeIcon: sidescanActive },
];
export const userRoles = [
  { text: 'Admin', value: '1' },
  { text: 'CP Manager', value: '2' },
  { text: 'CP', value: '3' },
  { text: 'Finance', value: '4' },
  { text: 'Commercial Team', value: '5' },
  { text: 'Media Team', value: '6' }
]
export const userStatus = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]
export const statusTypeOptions = [
  { text: 'Active', value: '1' },
  { text: 'In-Active', value: '0' },
]

export const isQr = [
  { text: 'No', value: '0' },
  { text: 'Yes', value: '1' },
]
export const processedTypeOptions = [
  { text: 'Process Again', value: '0' },
  { text: 'Done', value: '1' },
]
export const qrTTLHrs = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: '8', value: '8' },
  { text: '9', value: '9' },
  { text: '10', value: '10' },
  { text: '11', value: '11' },
  { text: '12', value: '12' },
  { text: '13', value: '13' },
  { text: '14', value: '14' },
  { text: '15', value: '15' },
  { text: '16', value: '16' },
  { text: '17', value: '17' },
  { text: '18', value: '18' },
  { text: '19', value: '19' },
  { text: '20', value: '20' },
  { text: '21', value: '21' },
  { text: '22', value: '22' },
  { text: '23', value: '23' },
  { text: '24', value: '24' },
]

export const country = [
  { value: 'Afghanistan', text: 'Afghanistan' },
  { value: 'Åland Islands', text: 'Åland Islands' },
  { value: 'Albania', text: 'Albania' },
  { value: 'Algeria', text: 'Algeria' },
  { value: 'American Samoa', text: 'American Samoa' },
  { value: 'Andorra', text: 'Andorra' },
  { value: 'Angola', text: 'Angola' },
  { value: 'Anguilla', text: 'Anguilla' },
  { value: 'Antarctica', text: 'Antarctica' },
  { value: 'Antigua and Barbuda', text: 'Antigua and Barbuda' },
  { value: 'Argentina', text: 'Argentina' },
  { value: 'Armenia', text: 'Armenia' },
  { value: 'Aruba', text: 'Aruba' },
  { value: 'Australia', text: 'Australia' },
  { value: 'Austria', text: 'Austria' },
  { value: 'Azerbaijan', text: 'Azerbaijan' },
  { value: 'Bahamas', text: 'Bahamas' },
  { value: 'Bahrain', text: 'Bahrain' },
  { value: 'Bangladesh', text: 'Bangladesh' },
  { value: 'Barbados', text: 'Barbados' },
  { value: 'Belarus', text: 'Belarus' },
  { value: 'Belgium', text: 'Belgium' },
  { value: 'Belize', text: 'Belize' },
  { value: 'Benin', text: 'Benin' },
  { value: 'Bermuda', text: 'Bermuda' },
  { value: 'Bhutan', text: 'Bhutan' },
  { value: 'Bolivia', text: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina' },
  { value: 'Botswana', text: 'Botswana' },
  { value: 'Bouvet Island', text: 'Bouvet Island' },
  { value: 'Brazil', text: 'Brazil' },
  { value: 'British Indian Ocean Territory', text: 'British Indian Ocean Territory' },
  { value: 'Brunei Darussalam', text: 'Brunei Darussalam' },
  { value: 'Bulgaria', text: 'Bulgaria' },
  { value: 'Burkina Faso', text: 'Burkina Faso' },
  { value: 'Burundi', text: 'Burundi' },
  { value: 'Cambodia', text: 'Cambodia' },
  { value: 'Cameroon', text: 'Cameroon' },
  { value: 'Canada', text: 'Canada' },
  { value: 'Cape Verde', text: 'Cape Verde' },
  { value: 'Cayman Islands', text: 'Cayman Islands' },
  { value: 'Central African Republic', text: 'Central African Republic' },
  { value: 'Chad', text: 'Chad' },
  { value: 'Chile', text: 'Chile' },
  { value: 'China', text: 'China' },
  { value: 'Christmas Island', text: 'Christmas Island' },
  { value: 'Cocos (Keeling) Islands', text: 'Cocos (Keeling) Islands' },
  { value: 'Colombia', text: 'Colombia' },
  { value: 'Comoros', text: 'Comoros' },
  { value: 'Congo', text: 'Congo' },
  { value: 'Congo, Democratic Republic', text: 'Congo, Democratic Republic' },
  { value: 'Cook Islands', text: 'Cook Islands' },
  { value: 'Costa Rica', text: 'Costa Rica' },
  { value: 'Cote D\'Ivoire', text: '' },
  { value: 'Croatia', text: 'Croatia' },
  { value: 'Cuba', text: 'Cuba' },
  { value: 'Cyprus', text: 'Cyprus' },
  { value: 'Czech Republic', text: 'Czech Republic' },
  { value: 'Denmark', text: 'Denmark' },
  { value: 'Djibouti', text: 'Djibouti' },
  { value: 'Dominica', text: 'Dominica' },
  { value: 'Dominican Republic', text: 'Dominican Republic' },
  { value: 'Ecuador', text: 'Ecuador' },
  { value: 'Egypt', text: 'Egypt' },
  { value: 'El Salvador', text: 'El Salvador' },
  { value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
  { value: 'Eritrea', text: 'Eritrea' },
  { value: 'Estonia', text: 'Estonia' },
  { value: 'Ethiopia', text: 'Ethiopia' },
  { value: 'Falkland Islands (Malvinas)', text: 'Falkland Islands (Malvinas)' },
  { value: 'Faroe Islands', text: 'Faroe Islands' },
  { value: 'Fiji', text: 'Fiji' },
  { value: 'Finland', text: 'Finland' },
  { value: 'France', text: 'France' },
  { value: 'French Guiana', text: 'French Guiana' },
  { value: 'French Polynesia', text: 'French Polynesia' },
  { value: 'French Southern Territories', text: 'French Southern Territories' },
  { value: 'Gabon', text: 'Gabon' },
  { value: 'Gambia', text: 'Gambia' },
  { value: 'Georgia', text: 'Georgia' },
  { value: 'Germany', text: 'Germany' },
  { value: 'Ghana', text: 'Ghana' },
  { value: 'Gibraltar', text: 'Gibraltar' },
  { value: 'Greece', text: 'Greece' },
  { value: 'Greenland', text: 'Greenland' },
  { value: 'Grenada', text: 'Grenada' },
  { value: 'Guadeloupe', text: 'Guadeloupe' },
  { value: 'Guam', text: 'Guam' },
  { value: 'Guatemala', text: 'Guatemala' },
  { value: 'Guernsey', text: 'Guernsey' },
  { value: 'Guinea', text: 'Guinea' },
  { value: 'Guinea-Bissau', text: 'Guinea-Bissau' },
  { value: 'Guyana', text: 'Guyana' },
  { value: 'Haiti', text: 'Haiti' },
  { value: 'Heard Island and Mcdonald Islands', text: 'Heard Island and Mcdonald Islands' },
  { value: 'Holy See (Vatican City State)', text: 'Holy See (Vatican City State)' },
  { value: 'Honduras', text: 'Honduras' },
  { value: 'Hong Kong', text: 'Hong Kong' },
  { value: 'Hungary', text: 'Hungary' },
  { value: 'Iceland', text: 'Iceland' },
  { value: 'India', text: 'India' },
  { value: 'Indonesia', text: 'Indonesia' },
  { value: 'Iran', text: 'Iran' },
  { value: 'Iraq', text: 'Iraq' },
  { value: 'Ireland', text: 'Ireland' },
  { value: 'Isle of Man', text: 'Isle of Man' },
  { value: 'Israel', text: 'Israel' },
  { value: 'Italy', text: 'Italy' },
  { value: 'Jamaica', text: 'Jamaica' },
  { value: 'Japan', text: 'Japan' },
  { value: 'Jersey', text: 'Jersey' },
  { value: 'Jordan', text: 'Jordan' },
  { value: 'Kazakhstan', text: 'Kazakhstan' },
  { value: 'Kenya', text: 'Kenya' },
  { value: 'Kiribati', text: 'Kiribati' },
  { value: 'Korea (North)', text: 'Korea (North)' },
  { value: 'Korea (South)', text: 'Korea (South)' },
  { value: 'Kosovo', text: 'Kosovo' },
  { value: 'Kuwait', text: 'Kuwait' },
  { value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
  { value: 'Laos', text: 'Laos' },
  { value: 'Latvia', text: 'Latvia' },
  { value: 'Lebanon', text: 'Lebanon' },
  { value: 'Lesotho', text: 'Lesotho' },
  { value: 'Liberia', text: 'Liberia' },
  { value: 'Libyan Arab Jamahiriya', text: 'Libyan Arab Jamahiriya' },
  { value: 'Liechtenstein', text: 'Liechtenstein' },
  { value: 'Lithuania', text: 'Lithuania' },
  { value: 'Luxembourg', text: 'Luxembourg' },
  { value: 'Macao', text: 'Macao' },
  { value: 'Macedonia', text: 'Macedonia' },
  { value: 'Madagascar', text: 'Madagascar' },
  { value: 'Malawi', text: 'Malawi' },
  { value: 'Malaysia', text: 'Malaysia' },
  { value: 'Maldives', text: 'Maldives' },
  { value: 'Mali', text: 'Mali' },
  { value: 'Malta', text: 'Malta' },
  { value: 'Marshall Islands', text: 'Marshall Islands' },
  { value: 'Martinique', text: 'Martinique' },
  { value: 'Mauritania', text: 'Mauritania' },
  { value: 'Mauritius', text: 'Mauritius' },
  { value: 'Mayotte', text: 'Mayotte' },
  { value: 'Mexico', text: 'Mexico' },
  { value: 'Micronesia', text: 'Micronesia' },
  { value: 'Moldova', text: 'Moldova' },
  { value: 'Monaco', text: 'Monaco' },
  { value: 'Mongolia', text: 'Mongolia' },
  { value: 'Montserrat', text: 'Montserrat' },
  { value: 'Morocco', text: 'Morocco' },
  { value: 'Mozambique', text: 'Mozambique' },
  { value: 'Myanmar', text: 'Myanmar' },
  { value: 'Namibia', text: 'Namibia' },
  { value: 'Nauru', text: 'Nauru' },
  { value: 'Nepal', text: 'Nepal' },
  { value: 'Netherlands', text: 'Netherlands' },
  { value: 'Netherlands Antilles', text: 'Netherlands Antilles' },
  { value: 'New Caledonia', text: 'New Caledonia' },
  { value: 'New Zealand', text: 'New Zealand' },
  { value: 'Nicaragua', text: 'Nicaragua' },
  { value: 'Niger', text: 'Niger' },
  { value: 'Nigeria', text: 'Nigeria' },
  { value: 'Niue', text: 'Niue' },
  { value: 'Norfolk Island', text: 'Norfolk Island' },
  { value: 'Northern Mariana Islands', text: 'Northern Mariana Islands' },
  { value: 'Norway', text: 'Norway' },
  { value: 'Oman', text: 'Oman' },
  { value: 'Pakistan', text: 'Pakistan' },
  { value: 'Palau', text: 'Palau' },
  { value: 'Palestinian Territory, Occupied', text: 'Palestinian Territory, Occupied' },
  { value: 'Panama', text: 'Panama' },
  { value: 'Papua New Guinea', text: 'Papua New Guinea' },
  { value: 'Paraguay', text: 'Paraguay' },
  { value: 'Peru', text: 'Peru' },
  { value: 'Philippines', text: 'Philippines' },
  { value: 'Pitcairn', text: 'Pitcairn' },
  { value: 'Poland', text: 'Poland' },
  { value: 'Portugal', text: 'Portugal' },
  { value: 'Puerto Rico', text: 'Puerto Rico' },
  { value: 'Qatar', text: 'Qatar' },
  { value: 'Reunion', text: 'Reunion' },
  { value: 'Romania', text: 'Romania' },
  { value: 'Russian Federation', text: 'Russian Federation' },
  { value: 'Rwanda', text: 'Rwanda' },
  { value: 'Saint Helena', text: 'Saint Helena' },
  { value: 'Saint Kitts and Nevis', text: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', text: 'Saint Lucia' },
  { value: 'Saint Pierre and Miquelon', text: 'Saint Pierre and Miquelon' },
  { value: 'Saint Vincent and the Grenadines', text: 'Saint Vincent and the Grenadines' },
  { value: 'Samoa', text: 'Samoa' },
  { value: 'San Marino', text: 'San Marino' },
  { value: 'Sao Tome and Principe', text: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', text: 'Saudi Arabia' },
  { value: 'Senegal', text: 'Senegal' },
  { value: 'Serbia', text: 'Serbia' },
  { value: 'Montenegro', text: 'Montenegro' },
  { value: 'Seychelles', text: 'Seychelles' },
  { value: 'Sierra Leone', text: 'Sierra Leone' },
  { value: 'Singapore', text: 'Singapore' },
  { value: 'Slovakia', text: 'Slovakia' },
  { value: 'Slovenia', text: 'Slovenia' },
  { value: 'Solomon Islands', text: 'Solomon Islands' },
  { value: 'Somalia', text: 'Somalia' },
  { value: 'South Africa', text: 'South Africa' },
  { value: 'South Georgia and the South Sandwich Islands', text: 'South Georgia and the South Sandwich Islands' },
  { value: 'Spain', text: 'Spain' },
  { value: 'Sri Lanka', text: 'Sri Lanka' },
  { value: 'Sudan', text: 'Sudan' },
  { value: 'Suriname', text: 'Suriname' },
  { value: 'Svalbard and Jan Mayen', text: 'Svalbard and Jan Mayen' },
  { value: 'Swaziland', text: 'Swaziland' },
  { value: 'Sweden', text: 'Sweden' },
  { value: 'Switzerland', text: 'Switzerland' },
  { value: 'Syrian Arab Republic', text: 'Syrian Arab Republic' },
  { value: 'Taiwan, Province of China', text: 'Taiwan, Province of China' },
  { value: 'Tajikistan', text: 'Tajikistan' },
  { value: 'Tanzania', text: 'Tanzania' },
  { value: 'Thailand', text: 'Thailand' },
  { value: 'Timor-Leste', text: 'Timor-Leste' },
  { value: 'Togo', text: 'Togo' },
  { value: 'Tokelau', text: 'Tokelau' },
  { value: 'Tonga', text: 'Tonga' },
  { value: 'Trinidad and Tobago', text: 'Trinidad and Tobago' },
  { value: 'Tunisia', text: 'Tunisia' },
  { value: 'Turkey', text: 'Turkey' },
  { value: 'Turkmenistan', text: 'Turkmenistan' },
  { value: 'Turks and Caicos Islands', text: 'Turks and Caicos Islands' },
  { value: 'Tuvalu', text: 'Tuvalu' },
  { value: 'Uganda', text: 'Uganda' },
  { value: 'Ukraine', text: 'Ukraine' },
  { value: 'United Arab Emirates', text: 'United Arab Emirates' },
  { value: 'United Kingdom', text: 'United Kingdom' },
  { value: 'United States', text: 'United States' },
  { value: 'United States Minor Outlying Islands', text: 'United States Minor Outlying Islands' },
  { value: 'Uruguay', text: 'Uruguay' },
  { value: 'Uzbekistan', text: 'Uzbekistan' },
  { value: 'Vanuatu', text: 'Vanuatu' },
  { value: 'Venezuela', text: 'Venezuela' },
  { value: 'Viet Nam', text: 'Viet Nam' },
  { value: 'Virgin Islands, British', text: 'Virgin Islands, British' },
  { value: 'Virgin Islands, U.S.', text: 'Virgin Islands, U.S.' },
  { value: 'Wallis and Futuna', text: 'Wallis and Futuna' },
  { value: 'Western Sahara', text: 'Western Sahara' },
  { value: 'Yemen', text: 'Yemen' },
  { value: 'Zambia', text: 'Zambia' },
  { value: 'Zimbabwe', text: 'Zimbabwe' }
];

export const formatTypes = [
  { text: 'Albania', value: '1' },
  { text: 'Germany', value: '2' },
  { text: 'Philippines', value: '3' },
]

export function getFormattedDate(date) {
  const date1 = new Date(date);                 // {object Date}
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = date1.getFullYear();
  let month = monthNames[date1.getMonth()];
  let day = date1.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}

export function getFormattedDateTime(date) {
  const date1 = new Date(date);  
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = date1.getFullYear();
  let month = monthNames[date1.getMonth()];
  let day = date1.getDate().toString().padStart(2, '0');
  const hours = date1.getUTCHours().toString().padStart(2, '0');
  const minutes = date1.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date1.getUTCSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

}










